
module.exports = function(config, error_handler) {
    var self = this;    
    self.url = config.tis_url;
	
	
	self.AlmaFirst = function(cb) {
		var req = self.url + '/ALMA/first';
		self.get(req, {}, cb);
	};
		
	self.AlmaLast = function(cb) {
		var req = self.url + '/ALMA/last';
		self.get(req, {}, cb);
	};	
	
	self.IsWeekend = function() {
		var today = new Date();
		return today.getDay() === 6 || today.getDay() === 0;
	};
	
	self.DateAddDays = function(date, days) {
		var dat = new Date(date.valueOf());
		dat.setDate(dat.getDate() + days);
		return dat;
	};
	
	self.GetDate = function(cb) {
		var today = new Date();
		if(!self.IsWeekend()) {
			cb(today);
			return;
		}
		
		self.AlmaFirst(function(first) {
			self.AlmaLast(function(last) {
				var aFirst = new Date(first.Datum);
				var aLast = new Date(last.Datum);
				
				var newDate = self.DateAddDays(today, -2);
				if(aFirst <= newDate && newDate <= aLast) {
					cb(newDate);
					return;
				}
				
				newDate = self.DateAddDays(today, 2);
				if(aFirst <= newDate && newDate <= aLast) {
					cb(newDate);
					return;
				}
				
				// Nothing we can do.. we need to import a new rebus export.
				cb(today);
			});
		});
	};
    
    self.get = function(url, param, cb) {
        $.ajax({
            url: url,
            data: param,
            context: document.body
        }).done(function(data) {
            cb(data);
        });
    };
    
    // Set error handling
    $( document ).ajaxError(function( event, request, settings ) {
        error_handler('Kan inte kontakta servern, prova igen senare.');
    });
    
    return {
        depAmount: function (stopId, cb) {
            var req = self.url + '/TIS/depAmount'; 			
			
            self.GetDate(function(date) {
				var start = new Date(date.toISOString().substr(0,11) + config.limit_start_time + ':00.000Z');                      
				var end = new Date(start.toISOString().substr(0,11) + config.limit_end_time + ':00.000Z');
				
				var data = {
					'stopId': stopId,
					'start': start.toISOString(),
					'end': end.toISOString()
				};
				self.get(req, data, cb);
				
			}); //new Date();            
        },
        
        stopLines: function(hplId, cb) {
            var req = self.url + '/TIS/stopLines';
            var data = {
                'stopId': hplId
            };
            self.get(req, data, cb);
        },
        
        stopsAt: function(lat, lon, cb) {
            var req = self.url + '/TIS/stopsAt';
            var data = {
                'lat': lat,
                'lon': lon,
                'maxC': 5 // 5 stops because we need to find the one that is actually closest
							   // Keep in mind that using more than 5 MAY cause issues with Google Maps API
							   // Since each HPL requires a request to the Directions api to get the real walking distance
            };                      
            self.get(req, data, cb);
        }
    };
};
