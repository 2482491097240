
module.exports = function(elem, config) {
    
    var self = this;
    
    self.geo = google.maps.geometry.encoding;
    self.region = config.region;
    
    self.directionsService = new google.maps.DirectionsService();
    self.geocoder = new google.maps.Geocoder();
    self.main_map = new google.maps.Map(elem, config.map_options());
    
    self.polylines = [];
    self.markers = [];
    
    
    self.handleLocationError = function(browserHasGeolocation, infoWindow, pos) {
        infoWindow.setPosition(pos);
        infoWindow.setContent(browserHasGeolocation ?
                              'Error: The Geolocation service failed.' :
                              'Error: Your browser doesn\'t support geolocation.');
    };
    
    self.SetMap = function(item) {
        item.setMap(self.main_map);
    };
    
    self.decodeLevels = function(encodedLevelsString) {
        var decodedLevels = [];

        for (var i = 0; i < encodedLevelsString.length; ++i) {
            var level = encodedLevelsString.charCodeAt(i) - 63;
            decodedLevels.push(level);
        }
        return decodedLevels;
    };
	
	
	google.maps.event.addListener(self.main_map, "click", function (event) {
		var pos = { 
			lat: event.latLng.lat(),
			lng: event.latLng.lng()
		};
		
		$(document).trigger( "google:click", pos );
	});

	
    return {
        route: function (from, to, cb) {
            var param = {
                origin: from,
                destination: to,
                travelMode: google.maps.TravelMode.WALKING,
                unitSystem: google.maps.UnitSystem.METRIC,
                optimizeWaypoints: true
            };                      
            self.directionsService.route(param, cb);
        },
        geocode: function(address, cb) {
            geocoder.geocode( { 'address': address, 'region': self.region }, cb);
        },
        setPolyline: function(polyline, order) {
            
            var decodedLevels = self.decodeLevels('BBBBBBBBBBBBBBBBBB');
            var decodedPath = self.geo.decodePath(polyline);
            
            var color = ['#00FF00', '#FF0000'];
            
            var path = new google.maps.Polyline({
                path: decodedPath,
                levels: decodedLevels,
                strokeColor: color[order],
                strokeOpacity: 1.0,
                strokeWeight: 5,
                zIndex: 10 - order
            });
            
            self.SetMap(path);
            self.polylines.push(path);
        },
        setIcon: function (name, LatLng, lbl, showInfo, customIcon) {
            		
            var gLatLng = new google.maps.LatLng(LatLng.lat, LatLng.lng);
            var marker = new google.maps.Marker({
				map: self.main_map,
                position: gLatLng,
                title: name,
				clickable: true,
				icon: (customIcon ? '/img/bus_stop_blue.png' : null)
            });

			marker.info = new google.maps.InfoWindow({
				content: name
			});

			google.maps.event.addListener(marker, 'click', function() {
				marker.info.open(self.main_map, marker);
			});
			
			if(showInfo === true) {
				marker.info.open(self.main_map, marker);
			}
			
            self.markers.push(marker);
        },
        clearMarkersPolylines: function() {
            $.each(self.polylines, function(idx, poly) {
                poly.setMap(null);
            });
            
            $.each(self.markers, function(idx, marker) {
                marker.setMap(null);
            });
            
            self.polylines = [];
            self.marker = [];
        },
        fitToBounds: function() {
            var bounds = new google.maps.LatLngBounds();
            $.each(self.polylines, function(idx, poly) {
                var pos_array = poly.getPath().getArray();
                $.each(pos_array, function(i, p) {
                    bounds.extend(p);
                });
            });
            self.main_map.fitBounds(bounds);
        }               
    };
};

