
module.exports = {
    //tis_url: 'http://10.8.0.14:3001/BT',
    //tis_url: 'http://localhost:3001/BT',
    tis_url: '/rebus/data',
    region : 'se',
    country: 'Sverige',
    //administrative_area_level_1: 'Blekinge län',
    administrative_area_level_1: 'Jönköpings län',  
    limit_start_time: '08:00',
    limit_end_time: '18:00',
    max_distance: 1000, // meters
    max_departures: 3,
    map_options: function() {
        return {
            center: {lat: 57.7560515, lng: 14.048175},
            zoom: 13,
            mapTypeId: 'hybrid'
        };
    }
};
