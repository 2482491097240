

module.exports = function() {
    $("#search-field").keyup(function(event){
        if(event.keyCode === 13){
            $("#search-btn").click();
        }
    });

    $('#search-btn').on('click', function(){

        var from = $('.search-query').val();
		if(!$('#search-btn').hasClass('disabled')) {
			if(from.length > 4) {
				$('#search-btn').addClass('disabled');
				$('#search-btn > span').text("Sök igen");
				$('.search-widget').addClass('got-result');
				ont_beslut.reset();

				ont_beslut.getLocationFromAddress(from);

				$('.result-text').text("Beräknar resväg, var god vänta..");
			}
			else {
				ont_beslut.show_error('Du måste ange minst 5 tecken');
			}
		}
    });

    $('.info-btn').hover(function() {
        if($('#info-box').css('display') === 'none') {
            $('#info-box').show();
        }
        else {
            $('#info-box').hide();
        }
    });
	
	$(document).on('google:click', function(event, pos) {		
		if(!$('#search-btn').hasClass('disabled')) {
			$('#search-btn').addClass('disabled');
			$('#search-btn > span').text("Sök igen");
			$('.search-widget').addClass('got-result');
			ont_beslut.reset();
			
			ont_beslut.calcFromClick(pos);
			
			$('.result-text').text("Beräknar resväg, var god vänta..");
		}
	});	
};
