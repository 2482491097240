
var ont_beslut = window.ont_beslut || {};

ont_beslut.show_error = function(error) {
    $('#error-message').html(error);
    $("#error-modal").modal();
	
	ont_beslut.reset();	
	$('#search-btn').removeClass('disabled');
	$('.result-text').text('Utvärdera genom att söka på din adress!');
};


var config = require('./config');
var rebus = require('./rebus_client');
var google_map = require('./map');
var events = require('./events');


var rc = rebus(config, ont_beslut.show_error);
var map;

ont_beslut.call_stack = [];
ont_beslut.isDoneTmr = null;

ont_beslut.evaluation = {
    hpl: []	
};

ont_beslut.candidate_list = {
	hpl: []
};

ont_beslut.candidate_call_stack = [];

ont_beslut.reset = function() {
    $('#detail-result').hide();
	
	// Reset variables
    ont_beslut.evaluation = {
        hpl: []
    };
	ont_beslut.candidate_list = {
		hpl: []
	};
	ont_beslut.call_stack = [];
	ont_beslut.candidate_call_stack = [];
	ont_beslut.isDoneTmr = null;
	
    $('#hpl-results').empty();
    map.clearMarkersPolylines();
};

ont_beslut.initMap = function() {
	ont_beslut.tryInitInterval = setInterval(function() {
		if(typeof google === 'undefined') {
			return;
		}
		
		var elem = document.getElementById('map');
		map = google_map(elem, config);
		clearInterval(ont_beslut.tryInitInterval);
	}, 1000);
};


ont_beslut.getProperties = function(obj) {
    var props = [];
    $.each(obj, function(key, val) {
        if(!$.isEmptyObject(val)) {
            props.push(key + '');
        }
    });
    return props;
};

ont_beslut.addToHplList = function(hpl) {
    var li = $('<li/>');
    li.append(
        $('<div/>').addClass('hpl-container row').append(
            $('<div/>').addClass('icon-container col-xs-1').append(
                $('<div/>').html('<span>'+hpl.label+'</span>').addClass('marker-icon'))
        ).append(
            $('<div/>').addClass('info-container col-xs-11').append(
                $('<h1/>').text(hpl.Name).addClass('title')
            ).append(
                $('<span/>').text(hpl.dist + 'm').addClass('distance label label-deault')
            ).append(
                $('<p/>').text('Linjer: ' + ont_beslut.getProperties(hpl.lines).join(', ')).addClass('lines')
            )
        )
    );
    li.appendTo($('#hpl-results'));
};


ont_beslut.NrOfDepartures = function() {
    var depCntDir1 = 0;
    var depCntDir2 = 0;

    var lines = {};
    var trips;

    $.each(ont_beslut.evaluation.hpl, function(idx, hpl) {

        if(hpl.dist < config.max_distance) {
            $.each(hpl.lines, function(nr, line) {
                if(!$.isEmptyObject(line)) {

                    if(typeof lines[nr] === "undefined") {
                        lines[nr] = [];
                    }

                    if(typeof line[1] !== "undefined") {

                        trips = line[1].Departures.filter(function(item) {
                            return typeof lines[nr][item.TripNr] === "undefined";
                        });

                        depCntDir1 += trips.length;
                        lines[nr] = lines[nr].concat(trips);
                    }
                    if(typeof line[2] !== "undefined") {

                        trips = line[2].Departures.filter(function(item) {
                            return typeof lines[nr][item.TripNr] === "undefined";
                        });

                        depCntDir2 += trips.length;
                        lines[nr] = lines[nr].concat(trips);
                    }
                }
            });
        }
    });

    return {
        dir1: depCntDir1,
        dir2: depCntDir2
    };
};

ont_beslut.show_result = function() {
    var dist = ont_beslut.evaluation.hpl[0].dist;
    var dist2 = ont_beslut.evaluation.hpl[1].dist;

    var nrOfDep = ont_beslut.NrOfDepartures();
    var reason = '';

    if(dist >= config.max_distance && dist2 >= config.max_distance) {
        $('.result-text').text("Du har rätt till närtrafik!");
        $('#result-text-widget').text("Du har rätt till närtrafik!");
        reason = "Busshållplatserna ligger för långt bort!";
    } 
	// If a direction has 0 trips in only one direction then it's very likely that there shouldn't be any trips in that direction at all
    else if ( (nrOfDep.dir1 !== 0 && nrOfDep.dir1 < config.max_departures) || 
	             (nrOfDep.dir2 !== 0 && nrOfDep.dir2 < config.max_departures) ||
				 (nrOfDep.dir1 === 0 && nrOfDep.dir2 === 0)) {
        $('.result-text').text("Du har rätt till närtrafik!");
        $('#result-text-widget').text("Du har rätt till närtrafik!");
        reason = "Det avgår för få resor från busshållplatserna!";
    }
    else {
        $('.result-text').text("Du har inte rätt till närtrafik");
        $('#result-text-widget').text("Du har inte rätt till närtrafik");
        reason = "Det finns busshållplatser i närheten med regelbundna avgångar!";
    }

    $.each( ont_beslut.evaluation.hpl, function(idx, item) {
        ont_beslut.addToHplList(item);
    });
	
	$('#search-btn').removeClass('disabled');
    $('#result-reason').text(reason);
    $('#detail-result').show();
    $('.info-btn').click();
};

ont_beslut.validate_geocode = function(geo) {
    return geo.filter(function(loc) {
        return loc.address_components.filter(function(comp) {
            var admin = comp.types.filter(function(t) { 
                return t === 'administrative_area_level_1';
            });                     
            var country = comp.types.filter(function(t) { 
                return t === 'country';
            });
            
            if(admin.length > 0) {
                return comp.long_name.toLowerCase() === config.administrative_area_level_1.toLowerCase();
            }
            if(country.length > 0) {
                return comp.short_name.toLowerCase() === config.region.toLowerCase();
            }
            
            return false;
        }).length > 1;
    });
};

ont_beslut.getLocationFromAddress = function(address) {

    map.geocode( address, function(results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
            
            var geo_res = ont_beslut.validate_geocode(results);
            var err = false;
			
			
			
            if(geo_res.length === 0) {
				err = true;
            }
			else if (geo_res[0].address_components.length <= 4) {
				err = true;
			}
            else {
                geo_res = geo_res[0];
            }
			
			if(err) {
				ont_beslut.show_error('Vi kan tyvärr inte hitta den sökta adressen.<br />Vänligen kontrollera stavning eller förfina din sökning genom att även ange ort eller postnummer.<br /><br />Systemet begränsar till adresser inom ' + config.administrative_area_level_1 + '.');				
                return;
			}
            
            var pos = {
                lat: geo_res.geometry.location.lat(),
                lng: geo_res.geometry.location.lng()
            };
			
            map.setIcon(geo_res.formatted_address, pos, null, true, false);
			ont_beslut.processStopsFromPosition(pos);            
        }
        else {
            ont_beslut.show_error('Vi kan tyvärr inte hitta den sökta adressen.<br />Vänligen kontrollera stavning eller förfina din sökning genom att även ange ort eller postnummer.<br /><br />Systemet begränsar till adresser inom ' + config.administrative_area_level_1 + '.');
            //alert("Geocode was not successful for the following reason: " + status);
        }
    });
};

ont_beslut.calcFromClick = function(pos) {
	map.setIcon("Utgångspunkt", pos, null, true, false);
	ont_beslut.processStopsFromPosition(pos);
};

ont_beslut.processStopsFromPosition = function(pos) {
	rc.stopsAt(pos.lat, pos.lng, function(data) {
		if(typeof data === "undefined" || data.getStopsAtResult === null || data.getStopsAtResult.length === 0) {
			ont_beslut.show_error('Gick inte att hämta hållplatser, vänligen försök igen senare.');
			return;
		}

		data = data.getStopsAtResult;
		for( var i = 0; i < data.length; i++ ) {
			var item = data[i];

			var loc = new google.maps.LatLng(pos.lat, pos.lng);
			var to = new google.maps.LatLng(item.Lat, item.Lon);

			if(typeof ont_beslut.candidate_list.hpl[i] === "undefined") {
				ont_beslut.candidate_list.hpl[i] = {};
			}

			var hpl = ont_beslut.candidate_list.hpl[i];

			hpl.Name = item.Hpl_name;
			hpl.Id = item.Hpl_id;
			hpl.Location = {
				lat: item.Lat,
				lng: item.Lon
			};
			
			ont_beslut.candidate_call_stack.push(1);
			ont_beslut.handleDirection(loc, to, hpl);
		}
	});
};

ont_beslut.handleDepartures = function(hpl) {

    hpl.lines = {};

	ont_beslut.call_stack.push(1);
	rc.depAmount(hpl.Id, function(result) {

		if(typeof result === "undefined" || result === null) {
			ont_beslut.show_error('Gick inte att hämta information om linjerna, vänligen försök igen senare.');
			return;
		}

		for(var j = 0; j < result.length; j++) {
			
			if(typeof hpl.lines[result[j].LineNr] === "undefined") {
				hpl.lines[result[j].LineNr] = {};
			}
			
			if(typeof hpl.lines[result[j].LineNr][result[j].Direction] === "undefined") {
				hpl.lines[result[j].LineNr][result[j].Direction] = {};
			}

			hpl.lines[result[j].LineNr][result[j].Direction].DepartureCount = result[j].DepartureCount;
			hpl.lines[result[j].LineNr][result[j].Direction].Departures = result[j].Departures;		

			if(typeof hpl.lines[result[j].LineNr][result[j].Direction].Description !== "undefined") {
				hpl.lines[result[j].LineNr][result[j].Direction].Description = line.Description;
			}			
		}

		ont_beslut.call_stack.pop();
		if(ont_beslut.call_stack.length === 0) {
			if(ont_beslut.isDoneTmr !== null) {
				clearTimeout(ont_beslut.isDoneTmr);
			}
			ont_beslut.isDoneTmr = setTimeout(function(){
				ont_beslut.isDoneTmr = null;
				$('#hpl-results').empty();
				ont_beslut.show_result();
			}, 200);
		}
	});

};

ont_beslut.handleDirection = function(origin, destination, eval_hpl) {
	map.route(origin, destination, function(result, status) {
		if (status === google.maps.DirectionsStatus.OK) {

			eval_hpl.overview_polyline = result.routes[0].overview_polyline;
			var dist = 0;
			for(var i in result.routes[0].legs) {
				dist += result.routes[0].legs[i].distance.value;
			}
			eval_hpl.dist = dist;

			ont_beslut.candidate_call_stack.pop();
			ont_beslut.filterCandidates();            
		}
		else {
			ont_beslut.show_error('Går inte att beräkna väg till hållplatsen.');
		}
	});
};

ont_beslut.filterCandidates = function() {	
	if(ont_beslut.candidate_call_stack.length === 0) {
		var labels = ['A', 'B'];
		
		
		var sorted = ont_beslut.candidate_list.hpl.sort(function(a, b) {
			return a.dist > b.dist ? 1 : (a.dist < b.dist ? -1 : 0);
		});
		
		
		for(var i = 0; i < 2; i++) {
			
			map.setPolyline(sorted[i].overview_polyline, i);
			map.setIcon(sorted[i].Name, sorted[i].Location, labels[i], false, true);
			sorted[i].label = labels[i];
			
			if(typeof ont_beslut.evaluation.hpl[i] === "undefined") {
				ont_beslut.evaluation.hpl[i] = {};
			}
			ont_beslut.evaluation.hpl[i] = sorted[i];
			ont_beslut.handleDepartures(sorted[i]);
		}
		map.fitToBounds();
	}
};

$( document ).ready(function() {
    events();
	ont_beslut.initMap();
});

window.ont_beslut = ont_beslut;
